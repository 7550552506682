<template>
    <base-preloader-gif :size="40" />
</template>

<script>
import BasePreloaderGif from "@/components/Base/BasePreloaderGif.vue";
export default {
    name: 'PageAuth',
    components: {BasePreloaderGif}
}
</script>